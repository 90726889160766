<template>
  <div class="flex items-center justify-center w-full h-[100vh]">
    <div class="flex flex-col items-center w-full max-w-md space-y-8">
      <NYInput class="w-full mb-4" id="position-dropdown" label="Select Your Position:" :items="positions" placeholder="Choose an option" selection-message-template="You've selected {selection} as your favorite!" v-model="selectedPosition" />
      <NYInput class="w-full" id="section-dropdown" label="Select Your Section:" :items="sections" placeholder="Choose an option" selection-message-template="You've selected {selection} as your favorite!" v-model="selectedSection" />
          
      <button @click="submitSelection" :disabled="!isSelectionValid" class="w-full py-3 text-white transition duration-300 ease-in-out flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500" :class="{ 'bg-gray-400 cursor-not-allowed': !isSelectionValid, 'bg-teal-500 hover:bg-teal-600 cursor-pointer': isSelectionValid }">
        <transition name="slide-fade" mode="out-in">
          <span v-if="isSelectionValid" key="valid" class="text-[16px] mr-2">Next: Confirm Information
            <font-awesome-icon icon="fa-solid fa-arrow-right" class="text-white ml-2" /></span>
          <span v-else key="invalid" class="text-[16px] mr-2">Please choose your position and section</span>
        </transition>
      </button>    
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import NYInput from './NYInput.vue'

export default {
  components: {
    NYInput
  },
  props: {
    validateSelection: {
      type: Function,
      default: () => false
    },
    
  },
  setup(props, { emit }) {
    const positions = [
      { value: 'Judge', label: 'Judge' },
      { value: 'Coach', label: 'Coach' }
    ]

    const sections = Array.from({ length: 11 }, (_, i) => ({
      value: `Section ${i + 1}`,
      label: `Section ${i + 1}`
    }))

    const selectedPosition = ref(null)
    const selectedSection = ref(null)

    const isSelectionValid = computed(() => {
      return selectedPosition.value && selectedSection.value && selectedPosition.value !== '' && selectedSection.value !== ''
    })

    const emitValidationStatus = () => {
      emit('check-form-validated', isSelectionValid.value)
    }

    const saveSelections = () => {
      localStorage.setItem('selectedPosition', selectedPosition.value)
      localStorage.setItem('selectedSection', selectedSection.value)
    }

    onMounted(() => {
      emitValidationStatus()
      props.validateSelection(isSelectionValid)
      console.log('selectedPosition.value: ', selectedPosition.value)
      console.log('selectedSection.value: ', selectedSection.value)
    })

    watch([selectedPosition, selectedSection], () => {
      emitValidationStatus()
      saveSelections()
    })

    const submitSelection = () => {
      if (isSelectionValid.value) {
        props.validateSelection(selectedPosition.value, selectedSection.value)
        saveSelections()
        emit('add-cheer-data', { position: selectedPosition.value, section: selectedSection.value })
        emit('form-validated', { position: selectedPosition.value, section: selectedSection.value })
      }else{
        props.validateSelection(selectedPosition.value, selectedSection.value)
        alert('Please choose your position and section')
      }
    }

    return {
      positions,
      sections,
      selectedPosition,
      selectedSection,
      submitSelection,
      isSelectionValid
    }
  }
}
</script>