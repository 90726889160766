<template>
    <div class="relative flex items-center justify-center" :class="{ 'min-h-[90vh]': formType === 'full', 'min-h-[55vh]': formType !== 'full' }">
        <div class="absolute inset-0 pointer-events-none" :class="{ 'bg-white/0': showCropper }">
    
            <!-- Floating Profile Images with Depth -->
            <div v-for="(profile, index) in floatingProfiles" :key="index" class="absolute rounded-full overflow-hidden shadow-lg transform -translate-x-1/2 -translate-y-1/2 transition-all duration-1000 ease-in-out" :class="{ 'animate-float': !imageSelected, 'animate-final-position': imageSelected }" :style="getProfileStyle(profile, index)">
                <img :src="profile.image" alt="Random face" class="w-full h-full object-cover transition-transform duration-300 hover:scale-110" :style="{ filter: `blur(${profile.blurAmount}px)` }" />
            </div>
        </div>
    
        <div class="w-full max-w-xl mx-6 bg-gray-50 shadow-lg rounded-2xl overflow-hidden z-[999] transition-all duration-300 hover:shadow-xl border border-gray-200 animate__animated animate__fadeInRight" :class="{ 'mt-8': formType !== 'full' }" style="--animate-duration: 0.7s; background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3), 0 0 20px 0px rgba(0, 200, 200, 0.2);">
            <div class="p-6">
    
                <div @click="cropImage" class="w-full p-2 rounded-full transition-colors duration-200 mx-auto text-center" title="Crop">
                    <font-awesome-icon icon="fa-solid fa-crop" class="h-8 w-8 text-gray-600 mx-auto" />
                </div>
    
                <h2 class="text-xl text-gray-600 text-center mb-2 font-semibold">
                    Choose and Crop Your Profile Picture
                </h2>
                <p class="text-sm text-gray-500 mb-4 text-center">Click the circle to upload an image. You can then crop, rotate, and adjust your profile picture.</p>
                <div class="flex flex-col items-center">
                    <div class="w-full max-w-md mb-4">
                        <div v-if="!showCropper" class="relative">
                            <div :class="['w-32 h-32 mx-auto rounded-full flex justify-center items-center cursor-pointer overflow-hidden transition-all duration-300 hover:shadow-md transform hover:scale-105 group', {'': !userSelectedImage, 'border-2 border-solid border-gray-400': userSelectedImage}]" @click="triggerFileInput">
                                <input type="file" ref="fileInput" accept="image/*" @change="handleFileSelect" class="hidden">
                                <div v-if="!imageUrl" class="text-center text-gray-400 group-hover:text-gray-500 transition-colors">
                                    <i class="fas fa-camera text-4xl mb-2"></i>
                                    <p class="text-sm font-medium">Click to upload</p>
                                </div>
                                <img v-else :src="imageUrl" alt="Profile preview" class="w-full h-full object-cover" />
                                <div v-if="!userSelectedImage" class="absolute inset-0 border-4 border-dashed border-gray-300 rounded-full animate-spin-slow"></div>
                            </div>
                            <div v-if="userSelectedImage" class="text-center mt-2">
                                <p class="text-sm text-gray-500">{{ fileSize }}</p>
                            </div>
                        </div>
    
                        <div v-if="showCropper" class="mt-4">
                            <vue-cropper ref="cropper" :src="imageUrl" :aspect-ratio="1" :view-mode="1" :drag-mode="'move'" :guides="true" :center="true" :highlight="true" :background="true" :rotatable="true" :scalable="true" :zoomable="true" :modal="true" class="max-h-[40vh] mb-4 rounded-lg shadow-sm" style="background-color: white;"></vue-cropper>
                        </div>
                    </div>
    
                    <div class="w-full max-w-md">
                        <div v-if="showCropper" class="mb-4">
                            <div class="flex flex-wrap justify-center gap-2 mb-4">
                                <button @click="rotateLeft" class="cropper-control-btn bg-gray-100 hover:bg-gray-200 p-2 rounded-full transition-colors duration-200" title="Rotate Left">
                                    <font-awesome-icon icon="fa-solid fa-rotate-left" />
                                </button>
                                <button @click="rotateRight" class="cropper-control-btn bg-gray-100 hover:bg-gray-200 p-2 rounded-full transition-colors duration-200" title="Rotate Right">
                                    <font-awesome-icon icon="fa-solid fa-rotate-right" />
                                </button>
                                <button @click="flipHorizontal" class="cropper-control-btn bg-gray-100 hover:bg-gray-200 p-2 rounded-full transition-colors duration-200" title="Flip Horizontal">
                                    <font-awesome-icon icon="fa-solid fa-left-right" />
                                </button>
                                <button @click="flipVertical" class="cropper-control-btn bg-gray-100 hover:bg-gray-200 p-2 rounded-full transition-colors duration-200" title="Flip Vertical">
                                    <font-awesome-icon icon="fa-solid fa-up-down" />
                                </button>
                                <button @click="resetCrop" class="cropper-control-btn bg-gray-100 hover:bg-gray-200 p-2 rounded-full transition-colors duration-200" title="Reset">
                                    <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
                                </button>
                            </div>
    
                            <div class="mb-4">
                                <label for="zoom-slider" class="block text-sm font-medium text-gray-600 mb-1">Zoom</label>
                                <input type="range" id="zoom-slider" v-model="zoomValue" min="0.1" max="3" step="0.1" @input="zoomImage" class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
                            </div>
    
                            <div class="flex justify-center space-x-3">
                                <button @click="cancelCrop" class="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg transition-colors hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300">
                                    Cancel
                                </button>
                                <button @click="cropImage" class="px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-lg transition-colors hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
                                    <font-awesome-icon icon="fa-solid fa-crop" class="mr-2" /> Apply </button>
                            </div>
                        </div>
    
                        <div class="mt-4 w-full flex flex-col items-center justify-center">
                            <div class="w-full flex flex-col justify-center space-y-3 mt-3">
                                <button v-if="!imageSelected && !showCropper" @click="uploadLater" class="mx-auto flex items-center justify-center">
                                    <div class="flex items-center justify-center">
                                        <font-awesome-icon icon="fa-solid fa-clock" class="mr-2" />
                                        <span class="text-[15px]">
                                            I'll Do This Later
                                        </span>
                                    </div>
                                </button>
    
                                <button v-if="imageSelected && !showCropper" @click="editCrop" class="w-full btn btn-primary hover:scale-105 active:scale-95">
                                    <font-awesome-icon icon="fa-solid fa-crop" class="mr-2" /> Edit Crop
                                </button>
                            </div>
    
                            <div class="w-full mt-3">
                                <button v-if="imageSelected && !showCropper" @click="continueToNextStep" class="w-full btn btn-success rounded-md py-3 transition-transform duration-200 hover:scale-105 active:scale-95 bg-teal-400 hover:bg-teal-400/90 cursor-pointer text-white">
                                    Continue <font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2" /> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    
    export default {
        components: {
            VueCropper,
        },
        props: {
            formType: {
                type: String,
                default: 'full'
            }
        },
        data() {
            return {
                imageUrl: 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg',
                showCropper: false,
                imageSelected: false,
                floatingProfiles: [],
                zoomValue: 1,
                userSelectedImage: false,
                fileName: '',
                fileSize: 0,
            };
        },
        mounted() {
            try {
                this.floatingProfiles = this.generateFloatingProfiles();
                this.loadProfileImage();
            } catch (error) {
                console.error('Error in UploadProfileImage mounted hook:', error);
            }
        },
        methods: {
            generateFloatingProfiles() {
                const numProfiles = this.formType === 'full' ? 40 : 32; // 20% less profiles for non-full form
                const profiles = [];
                const minDistance = 10; // Minimum distance between profiles
                const maxAttempts = 100; // Maximum attempts to place a profile
    
                for (let i = 0; i < numProfiles; i++) {
                    let profile;
                    let attempts = 0;
    
                    do {
                        profile = this.generateProfile();
                        attempts++;
                    } while (this.isOverlapping(profile, profiles) && attempts < maxAttempts);
    
                    if (attempts < maxAttempts) {
                        profiles.push(profile);
                    }
                }
    
                return profiles;
            },
            generateProfile() {
                const depth = 0.5 + Math.random() * 0.7;
                const left = Math.random() * 100;
                const top = Math.random() * 100;
    
                // Calculate distance from center (50%, 50%)
                const distance = Math.sqrt(Math.pow(left - 50, 2) + Math.pow(top - 50, 2));
    
                return {
                    left: `${left}%`,
                    top: `${top}%`,
                    image: `https://randomuser.me/api/portraits/${Math.random() > 0.5 ? 'men' : 'women'}/${Math.floor(Math.random() * 100)}.jpg`,
                    size: 40 + depth * 50,
                    zIndex: Math.floor(depth * 20),
                    opacity: 0.2 + depth * 0.25,
                    distance: distance,
                    shadowBlur: 1 + depth * 2,
                    shadowSpread: 5 + depth * 10,
                    shadowOpacity: 0.25 + depth * 0.25,
                    borderOpacity: 0.1 + depth * 0.2,
                    borderColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`
                };
            },
            isOverlapping(newProfile, existingProfiles) {
                const minDistance = 10;
                return existingProfiles.some(profile => {
                    const dx = parseFloat(newProfile.left) - parseFloat(profile.left);
                    const dy = parseFloat(newProfile.top) - parseFloat(profile.top);
                    const distance = Math.sqrt(dx * dx + dy * dy);
                    return distance < minDistance;
                });
            },
            getProfileStyle(profile, index) {
                const maxDistance = Math.sqrt(2) * 50; // Maximum possible distance from center
                const opacityFactor = 1 - (profile.distance / maxDistance);
    
                return {
                    left: profile.left,
                    top: profile.top,
                    width: `${profile.size}px`,
                    height: `${profile.size}px`,
                    animationDelay: `${index * 0.1}s`,
                    zIndex: profile.zIndex,
                    opacity: profile.opacity * opacityFactor, // Adjust opacity based on distance
                    boxShadow: `0 ${profile.shadowBlur}px ${profile.shadowSpread}px rgba(0, 0, 0, ${profile.shadowOpacity}), 0 0 0 3px rgba(209, 213, 219, ${profile.borderOpacity})`
                };
            },
            saveProfileImage() {
                try {
                    localStorage.setItem('profileImage', this.imageUrl);
                    this.showAlert = false; // Clear any previous alerts
                } catch (error) {
                    console.error('Error saving profile image:', error);
                this.showAlert = true;
                // Fallback: save a flag indicating the image is too large
                localStorage.setItem('profileImageTooLarge', 'true');
            }
        },

        loadProfileImage() {
            try {
                const savedImage = localStorage.getItem('profileImage');
                if (savedImage) {
                    this.imageUrl = savedImage;
                    this.imageSelected = true;
                    this.userSelectedImage = true;
                    this.getFileSizeFromDataUrl(savedImage);
                } else if (localStorage.getItem('profileImageTooLarge') === 'true') {
                    this.showAlert = true;
                }
            } catch (error) {
                console.error('Error loading profile image:', error);
            }
        },

        cropImage() {
            const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
            if (croppedCanvas) {
                this.imageUrl = croppedCanvas.toDataURL();
                this.showCropper = false;
                this.imageSelected = true;
                this.randomizeProfiles();
                this.saveProfileImage();
                this.getFileSizeFromDataUrl(this.imageUrl);
            }
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileSelect(e) {
            const file = e.target.files[0];
            if (file && file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageUrl = e.target.result;
                    this.showCropper = true;
                    this.userSelectedImage = true;
                    this.getFileSize(file);
                };
                reader.readAsDataURL(file);

                // Assign filename
                this.fileName = file.name;
            } else {
                alert('Please select a valid image file.');
            }
        },
        getFileSize(file) {
            const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
            this.fileSize = `${fileSizeMB} MB`;
        },
        getFileSizeFromDataUrl(dataUrl) {
            if (!dataUrl) {
                this.fileSize = '0 MB';
                return;
            }
            const base64String = dataUrl.split(',')[1];
            if (!base64String) {
                this.fileSize = '0 MB';
                return;
            }
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
            const rawData = window.atob(base64);
            const fileSizeMB = (rawData.length / (1024 * 1024)).toFixed(2);
            this.fileSize = `${fileSizeMB} MB`;
        },
        cancelCrop() {
            this.showCropper = false;
            if (!this.imageUrl) {
                this.$refs.fileInput.value = '';
                this.imageSelected = false;
                this.userSelectedImage = false;
                this.fileSize = 0;
            }
        },
        uploadLater() {
            this.$emit('image-uploaded', this.imageUrl);
            this.saveProfileImage();
        },
        randomizeProfiles() {
            this.floatingProfiles = this.generateFloatingProfiles();
        },
        zoomImage() {
            if (this.$refs.cropper) {
                this.$refs.cropper.zoomTo(this.zoomValue);
            }
        },
        rotateLeft() {
            this.$refs.cropper.rotate(-90);
        },
        rotateRight() {
            this.$refs.cropper.rotate(90);
        },
        flipHorizontal() {
            this.$refs.cropper.scaleX(-this.$refs.cropper.getData().scaleX || -1);
        },
        flipVertical() {
            this.$refs.cropper.scaleY(-this.$refs.cropper.getData().scaleY || -1);
        },
        resetCrop() {
            this.$refs.cropper.reset();
        },
        continueToNextStep() {
            this.$emit('image-uploaded', this.imageUrl);
            this.saveProfileImage();
        },
        editCrop() {
            this.showCropper = true;
        },
        resetForm() {
            this.imageUrl = 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg';
            this.showCropper = false;
            this.imageSelected = false;
            this.userSelectedImage = false;
            this.fileName = '';
            this.fileSize = 0;
            localStorage.removeItem('profileImage');
        },
    },
};
</script>

<style scoped>
@keyframes spin-slow {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-slow-reverse {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.animate-spin-slow {
    animation: spin-slow 2s linear infinite, spin-slow-reverse 2s linear infinite;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 12s;
}

@keyframes float {
    0%,
    100% {
        transform: translate(-50%, -50%) translateY(0) rotate(0deg);
    }

    25% {
        transform: translate(-50%, -50%) translateY(-20px) rotate(5deg);
    }

    50% {
        transform: translate(-50%, -50%) translateY(-30px) rotate(-5deg);
    }

    75% {
        transform: translate(-50%, -50%) translateY(-20px) rotate(3deg);
    }
}

.animate-float {
    animation: float 10s ease-in-out infinite;
}

@keyframes final-position {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    100% {
        transform: translate(-50%, -50%) scale(0.8);
    }
}

.animate-final-position {
    animation: final-position 0.5s ease-out forwards;
}

.cropper-control-btn {
    padding: 0.5rem;
    color: #6b7280;
    background-color: #f3f4f6;
    border-radius: 9999px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.cropper-control-btn:hover {
    background-color: #e5e7eb;
    color: #374151;
}

/* Custom styles for the range input */
input[type="range"] {
    -webkit-appearance: none;
    background-color: #e5e7eb;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    background-color: #4b5563;
    border-radius: 9999px;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #4b5563;
    border-radius: 9999px;
    cursor: pointer;
    border: 0;
}
</style>