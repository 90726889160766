<template>

<div id="loading-content-overlay" class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[99999] overflow-hidden bg-white bg-opacity-90 flex flex-col items-center justify-center">
	
    <div class="grid place-items-center">
        <div aria-label="Loading..." role="status" class="text-center">
            <font-awesome-icon icon="fa-duotone fa-spinner-third" class="w-12 h-12" spin />
        </div>
    </div>

</div>

</template>

<script>

import {
    mapStores
} from "pinia";
import useMainStore from "@/stores/main";

export default {
    computed: {
        ...mapStores(useMainStore),
    },
}
</script>
