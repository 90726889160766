<template>
  <div class="relative flex items-center justify-center shadow-4xl overflow-hidden w-full translate duration-200 transition" :class="{ 'pl-8': formType === 'full' }">
    <transition name="slide-fade" mode="out-in">
      <div class="w-full shadow-2xl mx-auto transition-all duration-300 overflow-hidden relative"
           :class="{ 'opacity-50 blur-sm': isRegistering, 'h-[100vh]': formType === 'full', 'h-full': formType !== 'full' }">
        <div class="relative z-10" :class="{ 'py-12 ': formType === 'full' }">
          <div v-if="errorMessage" class="mt-3 text-sm text-red-600 text-center font-medium animate__animated animate__fadeInUp">
                        {{ errorMessage }}
                      </div>
          <div key="account-overview-and-form" 
               class="mx-auto shadow-4xl overflow-hidden animate-slide-fade-right flex items-center justify-center"
               :class="{ 'h-[110vh] -mt-[50px]': formType === 'full', 'h-full mt-0': formType !== 'full' }">

               
                      
            <div class="p-6 bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm transform transition-all duration-300 ease-in-out flex items-center justify-center"
                 :class="{ 'h-[110vh] w-full': formType === 'full', 'h-full w-full': formType !== 'full' }"
                 :style="formType === 'full' ? 'background-image: url(\'data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E\'); box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3), 0 0 20px 0px rgba(0, 200, 200, 0.2);' : ''">
              <div class="max-w-2xl w-full" :class="{ '-mt-8': formType === 'full', 'mt-0': formType !== 'full' }">
                <transition name="slide-fade" mode="out-in">
                  <div v-if="!showStateMap" key="user-data">
                    <!-- Profile Block -->
                    <div class="flex items-center mb-6 pb-3">
                      <img :src="profileImageSrc" alt="Profile" :class="{ 'w-24 h-24': formType === 'full', 'w-16 h-16': formType !== 'full' }" class="object-cover rounded-full border-2 border-teal-500 mr-4 shadow-sm" />
                      <div>
                        <h2 :class="{ 'text-xl': formType == 'full', 'text-lg': formType != 'full' }" class="text-gray-500">Almost there</h2>
                        <h2 :class="{ 'text-4xl': formType == 'full', 'text-2xl': formType != 'full' }" class="font-bold text-gray-800">{{ `${formData.firstName} ${formData.lastName}` }}!</h2>
                      </div>
                    </div>

                    <!-- Data Block -->
                    <div class="grid grid-cols-2 gap-4">
                      <div v-for="field in fields.filter(f => !f.isCustom)" :key="field.key" class="border-b border-gray-200 pb-2">
                        <span class="text-xs text-gray-600 block mb-1">{{ field.title }}</span>
                        <span v-if="field.key !== 'password'" class="text-sm text-gray-800 font-medium truncate">
                          {{ field.key === 'name' ? `${formData.firstName} ${formData.lastName}` : formData[field.key] || field.example }}
                        </span>
                        <div v-else class="flex items-center">
                          <span class="text-sm text-gray-800 font-medium">{{ showPassword ? formData.password : '••••••••' }}</span>
                          <button @click="togglePassword" class="ml-2 text-teal-500 hover:text-teal-700 transition duration-300 ease-in-out">
                            <font-awesome-icon :icon="showPassword ? 'fa-eye-slash' : 'fa-eye'" class="text-xs" />
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <h3 v-if="fields.filter(f => f.isCustom).length != 0" class="text-black font-bold text-xs mt-4">NY Cheer Options:</h3>
                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-2">
                      <div v-for="field in fields.filter(f => f.isCustom)" :key="field.key" class="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
                        <div class="flex items-center mb-2">
                          <font-awesome-icon :icon="field.key == 'Judge' ? field.icon : field.icon2" class="text-teal-500 mr-2" />
                          <span class="text-xs text-gray-600 font-medium uppercase tracking-wide">{{ field.title }}</span>
                        </div>
                        <span class="text-sm text-gray-800 block truncate">{{ formData[field.key] || field.example }}</span>
                      </div>
                    </div>

                    <!-- Button Block -->
                    <div class="mt-6">
                      <div class="mb-6">
                        <p class="text-sm mb-1">Are you a part of a state organization?</p>
                        <button @click="showStateMap = true" class="w-full py-2 text-gray-700 bg-gray-100 rounded-md transition duration-300 ease-in-out flex items-center justify-between border-2 border-transparent hover:border-gradient-to-r from-teal-400 to-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 hover:scale-[1.01] group overflow-hidden">
                          <div class="flex items-center px-4 relative w-full">
                            <img :src="'https://d3sjyulja6yeep.cloudfront.net/files/states.png'" class="mr-2 text-teal-500 w-auto h-6 absolute -left-12 transition-all duration-300 ease-in-out group-hover:left-2" />
                            <span class="text-[16px] transition-all duration-300 ease-in-out group-hover:ml-[35px]">{{ selectedStateAssociation != '' ? selectedStateAssociation : 'Select State Association' }}</span>
                          </div>
                          <font-awesome-icon icon="fa-solid fa-arrow-right" class="text-teal-500 px-4 flex-shrink-0" />
                        </button>
                      </div>

                      <div class="flex items-center mb-3">
                        <input type="checkbox" id="acceptTerms" v-model="acceptedTerms" class="w-4 h-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 transition-all duration-300 ease-in-out" />
                        <label for="acceptTerms" class="ml-2 block text-sm text-gray-700">
                          I accept the <a href="https://www.refreps.com/terms" class="underline font-medium text-teal-400 hover:text-teal-400/90 transition-all duration-300 ease-in-out" target="_blank">terms of service</a>
                        </label>
                      </div>
                      <RocketButton @click="handleRegister(formData)" :isClicked="isLaunching" :isRegistering="isRegistering" :disabled="!acceptedTerms" :accepted-terms="acceptedTerms" class="w-full shadow-sm" />
                      
                    </div>
                  </div>
                  <div v-else key="state-map" class="w-full h-full">
                    <button @click="showStateMap = false" class="mb-4 text-gray-600 hover:text-gray-800 transition duration-300">
                      <font-awesome-icon icon="fa-solid fa-arrow-left" class="mr-2" /> Back to Overview
                    </button>
                    <StatesMap :state="formData.state" @state-selected="handleStateSelection" @back-to-overview="showStateMap = false" />
                  </div>
                </transition>
              </div>
            </div>

          </div>
        </div>
      </div>
    </transition>

    <!-- Animation overlay -->
    <div v-if="isRegistering" class="absolute inset-0 pointer-events-none z-50 bg-gradient-to-b from-blue-100 to-blue-200">
      <div class="relative w-full h-full overflow-hidden">
        
        <!-- Clouds -->
        <div v-for="i in 20" :key="'cloud-' + i" 
             class="cloud" 
             :style="cloudStyle(i)">
        </div>

        <!-- Rocket (your existing implementation) -->
        <div class="rocket-container" :class="{ 'rocket-enter': !isExiting, 'rocket-exit': isExiting }" style="z-index: 60;">
          <RegisterRocket />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterRocket from './RegisterRocket.vue'
import RocketButton from './RocketButton.vue'
import StatesMap from './StatesMap.vue'

export default {
  name: 'SignUpForm',
  props: {
    userData: {
      type: Object,
      required: true
    },
    formType: {
      type: String,
      default: 'full'
    },
    handleRegister: {
      type: Function,
      required: true
    },
    isRegistering: {
      type: Boolean,
      default: false
    },
    showRocket: {
      type: Boolean,
      default: false
    },
    isExiting: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  components: {
    StatesMap,
    RocketButton,
    RegisterRocket
  },
  data() {
    return {
      isLaunching: false,
      preparingForLaunch: false,
      formData: this.userData,
      showStateMap: false,
      selectedStateAssociation: '',
      showPassword: false,
      acceptedTerms: false,
      fields: [{
        icon: 'fa-duotone fa-solid fa-user',
        title: 'Name',
        key: 'name',
        example: 'John Doe',
        isCustom: false
      },
      {
        icon: 'fa-duotone fa-solid fa-envelope',
        title: 'Email',
        key: 'email',
        example: 'john.doe@example.com',
        isCustom: false
      },
      {
        icon: 'fa-duotone fa-solid fa-map-pin',
        title: 'Zip Code',
        key: 'zipCode',
        example: '90210',
        isCustom: false
      },
      {
        icon: 'fa-duotone fa-solid fa-map',
        title: 'State',
        key: 'state',
        example: 'California',
        isCustom: false
      },
      {
        icon: 'fa-duotone fa-solid fa-phone',
        title: 'Phone',
        key: 'phone',
        example: '864 551 3744',
        isCustom: false
      },
      {
        icon: 'fa-duotone fa-solid fa-lock',
        title: 'Password',
        key: 'password',
        example: 'Alpha1234!',
        isCustom: false
      },
      ...(this.userData.companyID === "4b39f904-bd23-4bee-a536-bc9571ff37cb" ? [
        {
          icon: 'fa-duotone fa-solid fa-sitemap',
          icon2: 'fa-duotone fa-solid fa-sitemap',
          title: 'Section',
          key: 'section',
          example: 'Marketing',
          isCustom: true
        },
        {
          icon: 'fa-duotone fa-solid fa-gavel',
          icon2: 'fa-duotone fa-solid fa-user',
          title: 'Position',
          key: 'position',
          example: 'Manager',
          isCustom: true
        }
      ] : [])
      ],
      profileImageSrc: 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg',
    }
  },
  watch: {
    acceptedTerms(newValue) {
      this.$emit('accepted-terms-changed', newValue)
      localStorage.setItem('acceptedTerms', JSON.stringify(newValue))
    },
    selectedStateAssociation(newValue) {
      localStorage.setItem('selectedStateAssociation', newValue)
    },
  },
  methods: {
  
    handleStateSelection(stateAssociation) {
      this.selectedStateAssociation = stateAssociation
      this.showStateMap = false
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    loadProfileImage() {
      if (this.formData.profileImage) {
        const img = new Image()
        img.onload = () => {
          this.profileImageSrc = this.formData.profileImage
        }
        img.onerror = () => {
          console.error('Failed to load profile image')
        }
        img.src = this.formData.profileImage
      }
    },
    loadAcceptedTerms() {
      const savedTerms = localStorage.getItem('acceptedTerms')
      if (savedTerms !== null) {
        this.acceptedTerms = JSON.parse(savedTerms)
      }
    },
    loadSelectedStateAssociation() {
      const savedStateAssociation = localStorage.getItem('selectedStateAssociation')
      if (savedStateAssociation !== null) {
        this.selectedStateAssociation = savedStateAssociation
      }
    },
    cloudStyle() {
      const size = 50 + Math.random() * 100; // Random size between 50px and 150px
      const left = Math.random() * 100; // Random horizontal position
      const animationDuration = 2 + Math.random() * 3; // Random duration between 3s and 7s

      return {
        width: `${size}px`,
        height: `${size / 2}px`,
        left: `${left}%`,
        top: `${-size}px`,
        animationDuration: `${animationDuration}s`,
        animationDelay: `${Math.random() * 2}s`,
      };
    },
  },
  mounted() {
    this.loadProfileImage()
    this.loadAcceptedTerms()
    this.loadSelectedStateAssociation()
  }
}
</script>

<style scoped>
body {
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f8f9fa;
}

.card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  width: 800px;
  display: flex;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.left-panel {
  width: 200px;
  background-color: #e9ecef;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  color: #495057;
  margin-bottom: 20px;
  overflow: hidden;
}

.name {
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  text-align: center;
}

.right-panel {
  flex-grow: 1;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.data-item {
  display: flex;
  flex-direction: column;
}

.data-label {
  font-size: 12px;
  color: #6c757d;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.data-value {
  font-size: 16px;
  color: #212529;
  font-weight: 500;
}

.footer {
  grid-column: 1 / -1;
  font-size: 12px;
  color: #adb5bd;
  text-align: right;
  margin-top: 10px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-bottom-enter-active,
.slide-fade-bottom-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-bottom-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.slide-fade-bottom-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* Cloud styles */
.cloud {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  opacity: 0.8;
  animation: floatCloud linear infinite;
}

@keyframes floatCloud {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(120vh);
  }
}

.cloud::before,
.cloud::after {
  content: '';
  position: absolute;
  background: inherit;
  border-radius: inherit;
}

.cloud::before {
  width: 50%;
  height: 100%;
  top: -50%;
  left: 5%;
}

.cloud::after {
  width: 70%;
  height: 100%;
  top: -30%;
  right: 5%;
}

/* Rocket styles */
.rocket-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: all 1s ease-in-out;
  bottom: -100px; /* Start below the screen */
}

.rocket-enter {
  bottom: 50%; /* Fly to the center */
  animation: flyIn 1s ease-in-out;
}

.rocket-center {
  bottom: 50%; /* Stay in the center */
}

.rocket-exit {
  bottom: 120%; /* Fly out of the top */
  animation: flyOut 0.5s ease-in-out; /* Faster animation for flying out */
}

@keyframes flyIn {
  0% {
    bottom: -10px;
  }
  100% {
    bottom: 50%;
  }
}

@keyframes flyOut {
  0% {
    bottom: 50%;
  }
  100% {
    bottom: 120%;
  }
}

/* Animation overlay */
.animation-overlay {
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 50;
  background: linear-gradient(to bottom, #87CEEB, #E0F6FF);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.animation-overlay.show {
  opacity: 1;
}
</style>