<template>
    <DynamicStepRegistration 
    :initialSteps="initialSteps"
    :isAuthenticating="isAuthenticating"
  :orgID="orgID"
  :formType="formType"
  @register-complete="handleRegistrationComplete"
  @cheer-validate="handleCheerValidation"
  @back-to-home="handleBackToHome"
  @update-step-info="updateStepInfo"
  ref="dynamicStepRegistration"
    />
</template>
  
  <script>
  import { ref, markRaw, onMounted, onUnmounted } from 'vue'
  import DynamicStepRegistration from './DynamicStepRegistration.vue'
  import PersonalDetails from '@/components/Login/Register/PersonalDetails.vue'
  import UploadProfileImage from '@/components/Login/Register/UploadProfileImage.vue'
  import ConfirmInformationAndRegister from '@/components/Login/Register/ConfirmInformationAndRegister.vue'
  import NYSectionPositionOption from '@/components/Login/Register/NYSectionPositionOption.vue'
  
  export default {
    name: 'RegisterForm',
    components: {
      DynamicStepRegistration
    },
    props: {
      orgID: {
        type: String,
        required: true
      },
      formType: {
        type: String,
        required: true,
        default: 'full'
      },
      isAuthenticating: {
        type: Boolean,
        default: false
      }
    },
    setup(props, { emit }) {
      const initialSteps = ref([
        {
          title: 'Your details',
          smallTitle: 'Info',
          description: 'Provide your personal information',
          component: markRaw(PersonalDetails),
          active: true,
          validated: false,
          icon: 'fa-solid fa-user',
        },
        {
          title: 'Upload profile image',
          smallTitle: 'Image',
          description: 'Upload your profile image. You can change it later',
          component: markRaw(UploadProfileImage),
          active: false,
          validated: false,
          icon: 'faa-solid fa-image',
        },
        ...(window.location.href.includes('4b39f904-bd23-4bee-a536-bc9571ff37cb') ? [{
          title: 'Choose Cheer Options',
          smallTitle: 'Cheer',
          description: 'Confirm your section and positions',
          component: markRaw(NYSectionPositionOption),
          active: false,
          validated: false,
          icon: 'fa-solid fa-megaphone',
        }] : []),
        {
          title: 'Confirm Information',
          smallTitle: 'Confirmation',
          description: 'Review and confirm your details',
          component: markRaw(ConfirmInformationAndRegister),
          active: false,
          validated: false,
          icon: 'fa-solid fa-check-double',
        }
      ])

      const dynamicStepRegistration = ref(null)
  
      const handleRegistrationComplete = (userData) => {
        emit('register-complete', userData)
      }
  
      const handleBackToHome = () => {
        // emit an event to the parent component to navigate back to the home page
        emit('back-to-home')
      }

      const handleCheerValidation = (validated) => {
        // Handle cheer validation
        console.log('Cheer validation:', validated)
      }
      
      onMounted(() => {
        // Perform any necessary actions on component mount
        localStorage.removeItem('personalDetailsFormData');
        localStorage.removeItem('profileImage');
      })
      onUnmounted(() => {
        localStorage.removeItem('personalDetailsFormData');
        localStorage.removeItem('profileImage');
      })


  
      return {
        initialSteps,
        handleRegistrationComplete,
        handleBackToHome,
        handleCheerValidation,
      }
    }
  }
  </script>